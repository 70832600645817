.opt-style {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
}

.dialoag-box-opacity {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.6;
  z-index: 100;
  font-size: 100px;
  text-align: center;
  padding-top: 40px;
}
