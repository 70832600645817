//
// _table.scss
//

.table {
    th {
        font-weight: $font-weight-bold;
    }

    .table-light {
        color: var(--#{$prefix}body-color);
        border-color: $table-border-color;
        background-color: $table-head-bg;
    }
}

.table-bordered {
    border: $table-border-width solid $table-border-color;
}

.table-dark > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
    th,
    td {
        white-space: nowrap;
    }
}

.table > :not(:first-child) {
    border-top: 0;
}

//Table centered
.table-centered {
    td,
    th {
        vertical-align: middle !important;
    }
}

.table-images {
    width: 100px;
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
}